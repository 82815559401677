import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { PageSEO } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 28px 0 130px 0;

  @media (max-width: 1075px) {
    margin: 3px 0 130px 0;
  }

  & h1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
  }

  & p {
    &:empty {
      display: inline-block;
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 55px 0;
  }
`;

const Grid = styled.div`
  & .body-text {
    max-width: 870px;
    margin: 0 0 30px 0;

    & p {
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const Contact = ({ data }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  useEffect(() => {
    setPageBackgroundColor({
      text: `#163262`,
      background: `#F894B2`,
    });
  }, []);

  const content = data.prismicContact.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <div
          className="body-text"
          key={`single_body_text_${index}_${content.id}`}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: content.primary.title1.html,
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </div>
      );
    }
  });

  return (
    <>
      <PageSEO
        title={data.prismicContact.data.title.text}
        description={null}
        image={null}
        url={`https://yinkashonibare.com${data.prismicContact.url}`}
      />
      <Page>
        <Grid>{content}</Grid>
      </Page>
    </>
  );
};

export default withPreview(Contact);

export const query = graphql`
  {
    prismicContact {
      url
      data {
        title {
          text
        }
        body {
          ... on PrismicContactBodyText {
            id
            slice_type
            primary {
              title1 {
                html
              }
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
